<template>
  <!-- Start Service Area -->
  <div class="rn-service-area d-flex bg_color--3" id="service">
    <div class="rn-service-left w-50 rn-service-bg-wrapper">
      <v-fade-transition leave-absolute>
        <div
          v-if="activetab === 1"
          class="h-full rn-service-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div>
      </v-fade-transition>
    </div>

    <div class="rn-service-right w-50 d-flex align-items-center">
      <div class="text-left story-content rn-plr section-ptb-xl">
        <div class="content">
          <h2 class="section-title">Kaip mus paremti?</h2>
          <div class="service-wrapper">
            <!-- Start Single Service -->
            <div class="service">
              <h4
                @mouseover="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
              >
                  Jūs mokate pajamų mokestį valstybei, 1,5 % nuo šio mokesčio  galite paskirti mūsų bendruomenei.
              </h4>
              <p>Tikslus įmonės pavadinimas : "Saugų bendruomenė"</p>
              <p> Įmonės kodas: 193280743</p>
              <p>Adresas: Saugų k. Šilutės r. sav.</p>
              <p> Bankas -   "Swedbank".
                  Banko kodas    -    7300 .
                  Atsiskaitomosios sąskaitos Nr:  LT337300010089176897</p>
            </div>
            <!-- End Single Service -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Service Area -->
</template>

<script>
  export default {
    data() {
      return {
        el: "#service",
        activetab: 1,
        src1: require("../../assets/img/saugos/saugos_old.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .rn-service-area {
    overflow: hidden;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
